<template>
  <div class="ai-assistant">
    <div class="chat-header">
      <h3>AI 助手</h3>
      <Select v-model="model" class="model-select">
        <Option v-for="model in models" :key="model.id" :value="model.id">{{ model.id }}</Option>
      </Select>
      <Button type="primary" @click="startNewSession">新会话</Button>
      <Button type="default" @click="showSettingsModal = true">设置</Button>
    </div>
    <div class="chat-content" ref="chatContent">
      <div v-for="message in messages.slice().reverse()" :key="message.id" class="message" :class="message.sender">
        <MarkdownPreview :initialValue="message.text" />
        <span class="timestamp">{{ formatTimestamp(message.timestamp) }}</span>
        <Button type="text" class="copy-button" @click="copyMessage(message.text)">
          <Icon type="ios-copy" /> 复制
        </Button>
        <Button v-if="message.sender === 'user'" type="text" class="resend-button" @click="resendMessage(message.text)">
          <Icon type="ios-redo" /> 重新发送
        </Button>
      </div>
      <div v-if="loading" class="loading-indicator">加载中...</div>
    </div>
    <div class="chat-input">
      <input v-model="userInput" @keyup.enter="sendMessage" placeholder="输入消息..." />
      <Button type="primary" @click="sendMessage">
        <Icon type="ios-send" /> 发送
      </Button>
    </div>

    <!-- 设置模态框 -->
    <Modal v-model="showSettingsModal" title="设置">
      <div class="chat-settings">
        <Input v-model="apiBaseUrl" placeholder="API 地址" />
        <Input v-model="apiKey" placeholder="API 密钥" />
      </div>
    </Modal>
  </div>
</template>

<script>
import MarkdownPreview from '@/components/markdown/preview';

export default {
  data() {
    return {
      userInput: '',
      messages: [],
      apiBaseUrl: 'https://api.chatanywhere.tech/v1',
      apiKey: 'sk-XBNUHzIiymytQVHgwr9iOoeA6XfO6mff5QAV17YNst6kEvYR',
      model: 'gpt-4o-mini',
      models: [],
      showSettingsModal: false,
      loading: false,
      streamEnabled: true,
    };
  },
  methods: {
    startNewSession() {
      this.messages = [];
      this.messages.push({
        id: Date.now(),
        text: "用中文回答，全能助手",
        sender: "system",
        timestamp: new Date(),
      });
      this.scrollToBottom();
    },
    async sendMessage() {
      if (!this.userInput.trim()) return;

      const userMessage = { id: Date.now() + Math.random(), text: this.userInput, sender: 'user', timestamp: new Date() };
      this.messages.push(userMessage);
      this.userInput = '';
      this.loading = true;

      try {
        const response = await fetch(`${this.apiBaseUrl}/chat/completions`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.apiKey}`,
          },
          body: JSON.stringify({
            model: this.model,
            messages: this.messages.map(msg => ({
              role: msg.sender === 'user' ? 'user' : (msg.sender === 'system' ? 'system' : 'assistant'),
              content: msg.text,
            })),
            max_tokens: 300,
            temperature: 0.7,
            stream: this.streamEnabled,
          }),
        });

        const aiMessage = { 
          id: Date.now(), 
          text: '', 
          sender: 'ai', 
          timestamp: new Date() 
        };
        this.messages.push(aiMessage);

        const reader = response.body.getReader();
        const decoder = new TextDecoder();

        while (true) {
          const { done, value } = await reader.read();
          if (done) break;

          const text = decoder.decode(value);
          const lines = text.split('\n');

          for (const line of lines) {
            if (line.trim() === '') continue;
            if (line.trim() === 'data: [DONE]') break;
            
            try {
              if (!line.startsWith('data: ')) continue;
              const data = JSON.parse(line.replace('data: ', ''));
              if (data.choices && 
                  data.choices[0].delta && 
                  data.choices[0].delta.content) {
                aiMessage.text += data.choices[0].delta.content;
                this.$nextTick(() => {
                  this.scrollToBottom();
                });
              }
            } catch (error) {
              console.error('解析响应数据出错:', error, line);
            }
          }
        }
      } catch (error) {
        console.error('与AI通信出错:', error);
        const errorMessage = { 
          id: Date.now(), 
          text: '请求失败，请检查网络连接。', 
          sender: 'system', 
          timestamp: new Date() 
        };
        this.messages.push(errorMessage);
      } finally {
        this.loading = false;
      }
      this.scrollToBottom();
    },
    formatTimestamp(timestamp) {
      const date = new Date(timestamp);
      return date.toLocaleTimeString();
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const chatContent = this.$refs.chatContent;
        chatContent.scrollTop = chatContent.scrollHeight;
      });
    },
    copyMessage(text) {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(text).then(() => {
          this.$Message.success('消息已复制');
        }).catch(err => {
          console.error('复制失败:', err);
        });
      } else {
        // 回退方案
        const textarea = document.createElement('textarea');
        textarea.value = text;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
        this.$Message.success('消息已复制');
      }
    },
    async fetchModels() {
      try {
        const response = await fetch(`${this.apiBaseUrl}/models`, {
          headers: {
            Authorization: `Bearer ${this.apiKey}`,
          },
        });
        const data = await response.json();
        this.models = data.data;
      } catch (error) {
        console.error('Error fetching models:', error);
      }
    },
    async fetchHistory() {
      // Fetch history logic
    },
    resendMessage(text) {
      this.userInput = text;
      this.sendMessage();
    },
  },
  mounted() {
    this.messages.unshift({
      id: Date.now(),
      text: "用中文回答，全能助手",
      sender: "system",
      timestamp: new Date(),
    });
    this.fetchModels();
    this.fetchHistory();
    this.scrollToBottom();
  },
  updated() {
    this.scrollToBottom();
  },
  components: {
    MarkdownPreview,
  },
};
</script>

<style scoped>
.ai-assistant {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: linear-gradient(135deg, #e0eafc, #cfdef3);
  border-radius: 15px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  font-family: 'Open Sans', sans-serif;
}

.chat-header {
  background: linear-gradient(135deg, #6a11cb, #2575fc);
  color: white;
  padding: 20px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 15px 15px 0 0;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

h3 {
  margin: 0;
  font-size: 28px;
}

.model-select {
  margin-right: 20px;
  width: 180px;
}

.chat-content {
  flex-grow: 1;
  padding: 30px;
  overflow-y: auto;
  background-color: #ffffff;
  display: flex;
  flex-direction: column-reverse;
  height: 470px;
  position: relative;
}

.message {
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 12px;
  background-color: #f0f4f8;
  max-width: 75%;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  position: relative;
  display: flex;
  flex-direction: column;
}

.message:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

.message.user {
  align-self: flex-end;
  background-color: #4a90e2;
  color: white;
}

.message.ai {
  align-self: flex-start;
  background-color: #f1f1f1;
}

.timestamp {
  font-size: 12px;
  color: #888;
  margin-top: 5px;
  align-self: flex-end;
}

.loading-indicator {
  text-align: center;
  color: #888;
  font-size: 14px;
  margin-top: 10px;
}

.copy-button, .resend-button {
  margin-top: 10px;
  background-color: #6a11cb;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 10px 18px;
  font-size: 14px;
  transition: background-color 0.3s, transform 0.3s;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.copy-button:hover, .resend-button:hover {
  background-color: #2575fc;
  transform: translateY(-3px);
}

.chat-input {
  display: flex;
  align-items: center;
  padding: 20px;
  border-top: 1px solid #ddd;
  background-color: #ffffff;
}

.chat-input input {
  flex-grow: 1;
  margin-right: 15px;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 14px;
}

.chat-input button {
  padding: 12px 25px;
  font-size: 14px;
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.chat-input button:hover {
  background-color: #2575fc;
}

.chat-settings {
  padding: 25px;
  border-top: 1px solid #eee;
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.chat-settings input,
.chat-settings select {
  margin-bottom: 20px;
  padding: 14px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 14px;
}

.chat-settings button {
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 12px 25px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.chat-settings button:hover {
  background-color: #9013fe;
}
</style>